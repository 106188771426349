<template>
  <div class="hrdcloud-list-container">
    <MyDropdownList v-model="activeMenu" />
    <div class="hrdcloud-list-wrapper">
      <ul class="hrdcloud-list" data-grid="4">
        <li v-for="(item, idx) in items" :key="idx" class="hrdcloud-item">
          <article class="hrdcloud-card">
            <div class="hrdcloud-image">
              <div class="image">
                <a href="javascript:" class="image-link">
                  <img :src="item.thumnailSrc" alt="임시이미지"/>
                </a>
              </div>
              <div class="badges">
                <div v-if="item.seriesYn === 'y'" class="badge-active">
                  <span class="text">시리즈</span>
                </div>
                <div v-else-if="item.seriesYn !== 'y' && item.completeYn === 'y'" class="badge-inactive">
                  <span class="text">완료</span>
                  <i class="icon-check"></i>
                </div>
              </div>
            </div>
            <div class="hrdcloud-content">
              <div class="content-category">
                <span class="text">{{item.hrdCategory}}</span>
              </div>
              <div class="content-title">
                <h5 class="title"><a href="javascript:" class="title-link">{{item.hrdTitle}}</a></h5>
              </div>
              <div class="content-info">
                <div class="info">
                  <i class="icon-views"></i>
                  <span class="text">{{item.viewCnt}}</span>
                </div>
                <div class="info">
                  <i class="icon-like"></i>
                  <span class="text">{{item.favoriteCnt}}</span>
                </div>
              </div>
              <div class="content-meta">
                <span class="text">{{item.regDttm}}</span>
                <span class="text">{{item.playTime}}</span>
              </div>
              <div class="content-interest">
                <button class="kb-btn-interest" data-content="담기">
                  <i class="icon-interest"></i>
                </button>
              </div>
            </div>
          </article>
        </li>
      </ul>
     <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
    </div>
  </div>
</template>

<script>
import MyDropdownList from '@/components/my/MyDropdownComponent';
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {getItems, getPaging, initPaging, lengthCheck} from "@/assets/js/util";
import {reactive, watch, ref} from "vue";
import {ACT_GET_MY_SUBSCRIBE_LIST} from "@/store/modules/my/my";
import CommonPaginationFront from "@/components/CommonPaginationFront";

export default {
  name: 'MySubscribe',
  components: {CommonPaginationFront, MyDropdownList},
  setup() {
    const isActive = ref(false);
    const activeMenu = ref('연수');

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const items = ref([]);
    const paging = ref(initPaging(route));
    const search = reactive({
      hrdTitle:'',
    });

    const getSubscribeList = () => {
      store.dispatch(`my/${ACT_GET_MY_SUBSCRIBE_LIST}`, {
        hrdTitle: search.hrdTitle,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = []
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getSubscribeList();

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if (search.hrdTitle) query.hrdTitle = search.hrdTitle;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
    };


    watch(() => route.query, () => {
      if (route.name === 'MySubscribe') {
        if (route.query.pageNo) {
          paging.value.pageNo = route.query.pageNo;
        } else {
          paging.value.pageNo = 1;
        }
        if (route.query.pageSize) {
          paging.value.pageSize = route.query.pageSize;
        }
        getSubscribeList();
      }
    });

    return {
      isActive,
      activeMenu,
      items,
      paging,
      search,

      clickSearch,
      pagingFunc,
    }
  }
};
</script>